@font-face {
  font-family: 'VAGRoundedStd-Bold';
  font-style: bold;
  src: local('VAGRoundedStd-Bold'), url('./assets/VAGRoundedStd-Bold.otf') format('opentype')
}

@font-face {
  font-family: 'VAGRoundedStd-Black';
  font-style: bold;
  src: local('VAGRoundedStd-Black'), url('./assets/VAGRoundedStd-Black.otf') format('opentype')
}

@font-face {
  font-family: 'VAGRoundedStd-Light';
  font-style: normal;
  src: local('VAGRoundedStd-Light'), url('./assets/VAGRoundedStd-Light.otf') format('opentype')
}

@font-face {
  font-family: 'VAGRoundedStd-Thin';
  font-style: thin;
  src: local('VAGRoundedStd-Thin'), url('./assets/VAGRoundedStd-Thin.otf') format('opentype')
}

body {
  margin: 0;
  font-family: "VAGRoundedStd-Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* :root {
  --color-falta-pouco: #0CA1B4;
  --mob-height: 6rem;
  --mob-marginLeft: -1.3rem;
  --height: 9rem;
  --marginLeft: -2.05rem;
  --marginTop: 0px;
  --marginBottom: 0px;
  --primary: #1A2446;
  --secondary: #fd9930;
  --secondary-rgba: rgba(253, 153, 48, 0.5);
  --disabled: rgba(253, 153, 48, 0.5);
} */

/* LOGA */
/*
*/

/* NETSUL */

/* :root {
  --color-falta-pouco: #1C1A16;
  --mob-height: 4rem;
  --mob-marginLeft: -0.3rem;
  --height: 5rem;
  --marginLeft: -0.05rem;
  --marginTop: 30px;
  --marginBottom: 30px;
  --primary: #1C1A16;
  --secondary: #009640;
  --secondary-rgba: rgba(0, 150, 64, 0.5);
  --disabled: rgba(0, 150, 64, 0.5);
} */

/* JNNET */

:root {
  --color-falta-pouco: #111;
  --mob-height: 4rem;
  --mob-marginLeft: -0.3rem;
  --height: 5rem;
  --marginLeft: -0.05rem;
  --marginTop: 30px;
  --marginBottom: 30px;
  --primary: #1C1A16;
  --secondary: #009640;
  --secondary-rgba: rgba(0, 150, 64, 0.5);
  --disabled: rgba(0, 150, 64, 0.5);
}

/* AT3 */

/* :root {
  --color-falta-pouco: #0CA1B4;
  --mob-height: 4rem;
  --mob-marginLeft: -0.3rem;
  --height: 5rem;
  --marginLeft: -0.05rem;
  --marginTop: 30px;
  --marginBottom: 30px;
  --primary: #1A2446;
  --secondary: #63c4df;
  --secondary-rgba: rgba(99, 196, 223, 0.5);
  --disabled: rgba(99, 196, 223, 0.5);
} */
